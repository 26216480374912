import {
  compose,
  space,
  typography,
  color,
  layout,
  border,
  shadow,
} from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';

const Btn = styled.button`
  ${compose(space, typography, color, layout, border, shadow)}
  cursor: pointer;

  &:hover {
    background: ${props =>
      props.hoverColor ? props.hoverColor : props.theme.colors.secondaryLight};
  }
`;

Btn.propTypes = {
  ...propTypes.space,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.shadow,
};

export default Btn;
