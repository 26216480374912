import {
  space,
  layout,
  color,
  position,
  shadow,
  typography,
  variant,
  border,
} from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';

const Box = styled('div')(
  space,
  layout,
  color,
  position,
  shadow,
  typography,
  border,
  variant({
    prop: 'float',
    variants: {
      left: {
        float: 'left',
      },
      right: {
        float: 'right',
      },
    },
  }),
);

Box.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.color,
  ...propTypes.background,
  ...propTypes.position,
  ...propTypes.shadow,
  ...propTypes.typography,
  ...propTypes.variant,
  ...propTypes.border,
};

export default Box;
