import React from 'react';
import Box from './base/Box';
import PropTypes from 'prop-types';

const Container = props => {
  return (
    <Box maxWidth="max" mx="auto" px={[2, 3, 4]} {...props}>
      {props.children}
    </Box>
  );
};

Container.propTypes = {
  maxWidth: PropTypes.string,
  mx: PropTypes.string,
  px: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default Container;
