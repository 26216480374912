import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Flex from './base/Flex';
import A from './base/A';

const Link = styled(A)`
  text-align: center;
  color: ${({ theme }) => theme.colors.text};

  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Nav = props => {
  return (
    <Flex as="nav" alignItems="center" {...props}>
      <Link
        fontWeight="bold"
        ml={[0, null, null, 2]}
        px={[2, 3]}
        py={[3, null, null, 2]}
        color="text"
        activeClassName="active"
        to="/"
      >
        Home
      </Link>
      {props.items.map(({ node }) => {
        const isActive = ({ location }) => {
          return (
            location.pathname.includes(`/${node.slug}/`) && {
              style: { color: '#F18321' },
            }
          );
        };
        return (
          <Link
            key={node.slug}
            fontWeight="bold"
            ml={[0, null, null, 2]}
            px={[2, 3]}
            py={[3, null, null, 2]}
            to={`/${node.slug}/${node.subpages[0].slug}`}
            getProps={isActive}
          >
            {node.title}
          </Link>
        );
      })}
      <Link
        fontWeight="bold"
        ml={[0, null, null, 2]}
        px={[2, 3]}
        py={[3, null, null, 2]}
        color="text"
        activeClassName="active"
        partiallyActive={true}
        to={`/contact`}
      >
        Contact
      </Link>
      {props.button && props.button}
    </Flex>
  );
};

Nav.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Nav;
