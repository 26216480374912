import React from 'react';
import Icon from './Icon';
import styled from 'styled-components';
import { layout, space, color, border, typography } from 'styled-system';

const Btn = styled('button')(layout, space, color, border, typography, {
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  borderRadius: '3px',
});

const Button = props => {
  return (
    <Btn bg="primary" {...props} as="button" py={1} px={2}>
      {props.icon && (
        <Icon size="2em" name={props.icon} mr={props.children && 2} />
      )}
      {props.children}
    </Btn>
  );
};

Button.defaultProps = {};

Button.propTypes = {};

export default Button;
