import {
  space,
  layout,
  flexbox,
  color,
  position,
  shadow,
  border,
} from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';

const Flex = styled('div')(
  space,
  layout,
  flexbox,
  color,
  position,
  shadow,
  border,
  {
    display: 'flex',
  },
);

Flex.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.flexbox,
  ...propTypes.color,
  ...propTypes.position,
  ...propTypes.shadow,
  ...propTypes.border,
};

export default Flex;
