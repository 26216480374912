import {
  space,
  typography,
  color,
  layout,
  border,
  shadow,
} from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';

const Input = styled('input')(space, typography, color, layout, border, shadow);

Input.propTypes = {
  ...propTypes.space,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.shadow,
};

export default Input;
