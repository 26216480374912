import React from 'react';
import {
  space,
  border,
  fontWeight,
  fontSize,
  typography,
  layout,
  color,
  borderBottom,
} from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';
import { Link } from 'gatsby';
import validDomAttributes from '../../../util/validDomAttributes';

const A = styled(Link)(space, typography, color, border, layout);

const Anchor = styled(Link)(fontWeight, fontSize, color, borderBottom);

export const StyledAnchor = ({
  to,
  space,
  fontWeight,
  fontSize,
  color,
  withBorder,
  children,
  activeClassName,
  partiallyActive,
  ...props
}) => {
  return (
    <Anchor
      style={{ borderBottom: withBorder && '1px solid' }}
      fontWeight={fontWeight}
      fontSize={fontSize}
      to={to && to}
      color={color}
      activeClassName={activeClassName}
      {...validDomAttributes(props)}
    >
      {children}
    </Anchor>
  );
};

A.propTypes = {
  ...propTypes.space.isRequired,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.border,
};

StyledAnchor.propTypes = {
  ...propTypes.space,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.border,
  ...propTypes.layout,
};

export default A;
