import { space, typography, color, variant, layout } from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';

const Typography = styled('p')(
  space,
  typography,
  color,
  layout,
  variant({
    variants: {
      h1: {
        fontSize: 7,
      },
      h2: {
        fontSize: 6,
      },
      h3: {
        fontSize: 5,
      },
      h4: {
        fontSize: 4,
      },
      h5: {
        fontSize: 3,
      },
      p: {
        fontSize: [1, 2, 2],
      },
    },
  }),
);

Typography.propTypes = {
  ...propTypes.space,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.layout,
};

export default Typography;
