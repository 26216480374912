import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../util/icons';
import styled from 'styled-components';
import { space } from 'styled-system';
import Box from './base/Box';

const Svg = styled(Box)`
  ${space};
  fill: ${props => props.theme.colors[props.color] || props.color};
  display: inline-block;
  stroke-width: 0;
  width: ${props => props.size};
  height: ${props => props.size};
  vertical-align: middle;
`;

const Icon = props => {
  return (
    <Svg
      {...props}
      as="svg"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {icons[props.name].map((d, i) => (
        <path fillRule="evenodd" clipRule="evenodd" key={i} d={d} />
      ))}
    </Svg>
  );
};

Icon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  name: Object.keys(icons),
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
