import { layout, space, color, position } from 'styled-system';
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';

const List = styled('ul')(layout, space, color, position, {
  listStyle: props => props.listStyle,
});

List.defaultProps = {
  listStyle: 'none',
};

List.propTypes = {
  ...propTypes.layout,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.position,
};

export default List;
