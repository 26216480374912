// filters props object to only allow dom / html safe data- and aria- attributes
const whitelistedProps = [
  'children',
  'className',
  'id',
  'role',
  'tabIndex',
  'title',
];

// eslint-disable-next-line no-useless-escape
const validPropRegex = /^(data-|aria-).[a-z\-]*$/;
const validDomAttributes = props => {
  return Object.entries(props).reduce((attrs, [key, value]) => {
    if (whitelistedProps.includes(key)) {
      // allow any whitelisted props as is
      attrs[key] = value;
    } else if (
      validPropRegex.test(key) &&
      typeof value !== 'function' &&
      typeof value !== 'object'
    ) {
      // prop must be kebab-case starting with data- or aria-
      // additionally only props with strings, numbers, and booleans, and all values will be converted to strings
      attrs[key] = `${value}`;
    }
    return attrs;
  }, {});
};

export default validDomAttributes;
