import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Flex from './base/Flex';

import Box from './base/Box';
import List from './base/List';
import A from './base/A';
import Icon from './Icon';
import Typography from './base/Typography';
import SubscriptionForm from '../SubscriptionForm';

import isPathExistInUrl from '../../util/isPathExistInUrl';

const FooterSection = styled(Flex).attrs(props => ({
  as: 'section',
  flexDirection: props.flexDirection ? props.flexDirection : 'column',
  width: props.width ? props.width : ['full', '80%', '45%', '30%'],
}))``;

const Title = styled(Typography).attrs(props => ({
  variant: 'h5',
  color: 'primary',
  fontWeight: 'bold',
  mt: [null, null, null, '0'],
}))``;

const RightSectionBox = styled(Box).attrs(props => ({
  width: ['full', null, '45%', 'full'],
}))``;

const SocialLink = styled(A).attrs(props => ({
  as: 'a',
  marginRight: 3,
  color: 'primary',
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  border-radius: 100%;
  display: inline-flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
  }
`;

const SocialIcon = styled(Icon).attrs(props => ({
  size: '22px',
}))``;

const BottomLink = styled(A).attrs(props => ({
  m: '0 20px',
  color: 'text',
  fontWeight: 'semibold',
  fontSize: '1',
}))`
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledSpan = styled.span`
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Footer = ({ contact, footer, posts }) => {
  return (
    <footer>
      <Flex
        bg="primaryLight"
        size="full"
        justifyContent="center"
        py={['3', null, null, '4']}
      >
        <Flex
          maxWidth={['full', null, 'max']}
          px={[2, 3, 4]}
          justifyContent="space-between"
          flexDirection={['column', null, 'row']}
          flexWrap={['nowrap', null, 'wrap']}
          alignItems={['center', null, 'normal']}
        >
          <FooterSection>
            <Title>RECENTE BERICHTEN</Title>
            <List m={0} p={0}>
              {posts.edges.map(({ node: { title, slug, createdAt } }) => (
                <li key={slug}>
                  <A to={`/posts/${slug}`} activeClassName="active">
                    <Icon name="chevron_right" color="text" mr="2"></Icon>
                    <Typography
                      color="secondary"
                      fontSize="2"
                      fontWeight="semibold"
                      display="inline"
                    >
                      <StyledSpan>{title}</StyledSpan>
                    </Typography>
                    <Typography
                      fontSize="0"
                      color="text"
                      marginTop="2"
                      marginBottom="3"
                      marginLeft="24px"
                    >
                      {moment(createdAt).format('D MMMM YYYY')}
                    </Typography>
                  </A>
                </li>
              ))}
            </List>
          </FooterSection>
          <FooterSection>
            <Box>
              <Title>{footer.edges[0].node.title.toUpperCase()}</Title>
              <Box minHeight={[null, null, null, '160px']} my="3">
                <Typography fontSize="1">
                  {footer.edges[0].node.firstText}
                </Typography>
                <Typography fontSize="1">
                  {footer.edges[0].node.secondText}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Title>NIEUWSBRIEF</Title>
              <SubscriptionForm />
            </Box>
          </FooterSection>
          <FooterSection
            flexDirection={['column', null, 'row', 'column']}
            width={['full', '80%', 'full', '30%']}
            justifyContent={[null, null, 'space-between', 'flex-start']}
          >
            <RightSectionBox>
              <Title>CONTACT</Title>
              <Box minHeight="160px" my="3">
                <Typography fontWeight="semibold">
                  {contact.companyName}
                </Typography>
                <Typography fontWeight="semibold">{contact.address}</Typography>
                <a href={`tel:${contact.phone}`}>
                  <Typography mb="0" color="secondary" fontWeight="semibold">
                    <StyledSpan>T: {contact.phone}</StyledSpan>
                  </Typography>
                </a>
                <Typography mt="0" fontWeight="semibold">
                  F: {contact.fax}
                </Typography>
                <a
                  href={`mailto:${contact.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography color="secondary" fontWeight="semibold">
                    <StyledSpan>{contact.email}</StyledSpan>
                  </Typography>
                </a>
              </Box>
            </RightSectionBox>
            <RightSectionBox>
              <Title>VOLGE ONS</Title>
              {isPathExistInUrl(contact.facebook) && (
                <SocialLink href={contact.facebook}>
                  <SocialIcon name="facebook" />
                </SocialLink>
              )}
              {isPathExistInUrl(contact.linkedIn) && (
                <SocialLink href={contact.linkedIn}>
                  <SocialIcon name="linkedin" />
                </SocialLink>
              )}
              {isPathExistInUrl(contact.twitter) && (
                <SocialLink href={contact.twitter}>
                  <SocialIcon name="twitter" />
                </SocialLink>
              )}
              {isPathExistInUrl(contact.youtube) && (
                <SocialLink href={contact.youtube}>
                  <SocialIcon name="youtube" />
                </SocialLink>
              )}
            </RightSectionBox>
          </FooterSection>
        </Flex>
      </Flex>
      <Flex
        bg="primaryOpaque"
        height="50px"
        justifyContent="center"
        alignItems="center"
      >
        <BottomLink to="/disclaimer">Disclaimer</BottomLink>|
        <BottomLink to="/privacy">Privacy</BottomLink>|
        <BottomLink to="/over-deze-website">Over deze website</BottomLink>
      </Flex>
    </footer>
  );
};

export default Footer;
