import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *, *::after, *::before {
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  body {
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: 'Roboto', sans;
    min-height: 100%;
    min-width: 320px;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary}
  }

  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    display: none !important;
  }
`;

export default GlobalStyles;
