import React, { useState } from 'react';
import * as yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Box from './styled/base/Box';
import Btn from './styled/base/Btn';
import Button from './styled/Button';
import Flex from './styled/base/Flex';
import styled from 'styled-components';
import Input from './styled/base/Input';
import { useForm } from 'react-hook-form';
import Typography from './styled/base/Typography';

const Message = styled.span`
  & a {
    display: none;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  display: flex;
  border-radius: 100%;
  top: -15px;
  right: -15px;
  font-size: 0.7em;
  width: 30px;
  height: 30px;
  color: inherit;
  background-color: inherit;
`;

const SubscriptionSchema = yup.object().shape({
  email: yup
    .string()
    .email('E-mail is niet geldig')
    .required('E-mail mag niet leeg zijn'),
});

const SubscriptionForm = () => {
  const [result, setResult] = useState({});
  const { register, errors, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    validationSchema: SubscriptionSchema,
  });

  const onSubmit = async ({ email }) => {
    const result = await addToMailchimp(email);
    setResult(result);
    reset();
  };

  return (
    <>
      {result.result && (
        <Box
          position="relative"
          bg={
            result.result === 'error'
              ? '#ff7f7f'
              : result.result === 'success' && '#a7e2af'
          }
          color={
            result.result === 'error'
              ? '#740000'
              : result.result === 'success' && '#508253'
          }
          p={2}
          mb={2}
          borderRadius="5px"
          fontSize="14px"
        >
          <Message dangerouslySetInnerHTML={{ __html: result.msg }} />
          <CloseButton icon="close" onClick={() => setResult({})} />
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex>
          <Input
            style={{ flex: 1 }}
            ref={register}
            name="email"
            type="email"
            placeholder="Email"
            fontSize="0"
            height="32px"
            p="0 0.4em"
            border="0"
            borderRadius="6px 0 0 6px"
            boxShadow="0"
          />
          <Btn
            type="submit"
            fontSize="0"
            color="background"
            bg="secondary"
            minWidth="90px"
            height="32px"
            p="0 0.4em"
            border="0"
            borderRadius="0 6px 6px 0"
            boxShadow="0"
          >
            Aanmelden
          </Btn>
        </Flex>
        {errors.email && (
          <Typography color="red" fontSize="14px" mt={1}>
            {errors.email.message}
          </Typography>
        )}
      </form>
    </>
  );
};

export default SubscriptionForm;
