import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Footer from './styled/Footer';
import Header from './styled/Header';
import Button from './styled/Button';
import Container from './styled/Container';

import theme from '../util/theme';
import GlobalStyles from '../util/GlobalStyles';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      contact: contentfulContactPage {
        companyName
        email
        fax
        phone
        address
        facebook
        linkedIn
        youtube
        twitter
      }
      links: allContentfulHeader(sort: { order: ASC, fields: order }) {
        edges {
          node {
            title
            slug
            subpages {
              slug
            }
          }
        }
      }
      posts: allContentfulPost(
        sort: { fields: createdAt, order: DESC }
        limit: 4
      ) {
        edges {
          node {
            title
            slug
          }
        }
      }
      footer: allContentfulFooter {
        edges {
          node {
            title
            firstText
            secondText
          }
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header
        navItems={data.links.edges}
        mb={3}
        button={
          <Link to="/donation" state={{ donation: {} }}>
            <Button
              fontSize="15px"
              height="30px"
              ml={[0, null, null, 3]}
              mt={[3, null, null, 0]}
              mb={[4, null, null, 0]}
              color="white"
              fontWeight="bold"
            >
              Doneer
            </Button>
          </Link>
        }
      />
      <Container minHeight="calc(100vh - 515px)" as="main">
        {children}
      </Container>
      <Footer posts={data.posts} footer={data.footer} contact={data.contact} />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
