import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from './base/Box';
import Flex from './base/Flex';
import Container from './Container';
import HeaderNav from './HeaderNav';
import Logo from '../../images/msa-logo.svg';
import Button from './Button';

const Header = props => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      bg="background"
      zIndex="1000"
      {...props}
      position="sticky"
      top="0"
      boxShadow={0}
      py={2}
    >
      <Container>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex as={Link} to="/">
            <img
              src={props.logo}
              alt="Mentorschap Logo"
              style={{ height: '60px' }}
            />
          </Flex>
          <Button
            bg="transparent"
            onClick={() => setOpen(!open)}
            icon={open ? 'close' : 'menu'}
            display={['block', null, null, 'none']}
            style={{ zIndex: '999999' }}
          />
          <HeaderNav
            style={{
              transition: 'max-height .7s ease-in-out',
              top: '76px',
              left: 0,
              right: 0,
              overflow: 'hidden',
            }}
            bg="background"
            boxShadow={[0, null, null, 'none']}
            position={['fixed', null, null, 'static']}
            flexBasis={['100%', null, null, 'auto']}
            flexDirection={['column', null, null, 'row']}
            display={['none', null, null, 'block']}
            maxHeight={[open ? '100%' : 0, null, null, '100%']}
            button={props.button}
            items={props.navItems}
          />
        </Flex>
      </Container>
    </Box>
  );
};

Header.defaultProps = {
  logo: Logo,
};

Header.propTypes = {
  logo: PropTypes.string.isRequired,
  navItems: PropTypes.array.isRequired,
};

export default Header;
