export default {
  name: 'Default',
  breakpoints: ['576px', '768px', '992px', '1200px'],
  colors: {
    primary: '#6E019A',
    primaryLight: '#F1E6F5',
    primaryOpaque: 'rgba(109, 19, 152, 0.3)',
    secondary: '#F18321',
    secondaryLight: '#f49724',
    secondaryOpaque: 'rgba(239, 131, 49, 0.1)',
    text: '#2E2E2E',
    background: '#FFFFFF',
  },
  space: ['0', '4px', '8px', '16px', '32px', '64px', '128px', '256px', '512px'],
  sizes: {
    min: '320px',
    max: '1140px',
    full: '100%',
    vw: '100vw',
    vh: '100vh',
  },
  fontSizes: [
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '24px',
    '32px',
    '48px',
    '64px',
    '96px',
    '128px',
  ],
  lineHeights: [1, 1.125, 1.25, 1.5],
  fontWeights: {
    normal: 400,
    semibold: 500,
    bold: 700,
  },
  letterSpacings: {
    normal: 'normal',
    caps: '0.25em',
    labels: '0.05em',
  },
  radii: ['0', '2px', '4px', '8px', '16px'],
  shadows: [
    '0 4px 10px rgba(0, 0, 0, .07)',
    '0px 10px 20px rgba(0, 0, 0, 0.15)',
  ],
};
